<template>
  <div>
    <div class="text-center" v-if="!dataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-else>
      <div ref="infoBlock">
        <PageTitle title="Шаблон расписания" />

        <v-row class="my-0">
          <v-col cols="12" class="pt-0 pb-3">
            <h4 class="department">{{ departmentName }}</h4>

            <h4 class="department mt-4">{{ this.scheduleTemplate.description }}</h4>
          </v-col>
        </v-row>

        <BreadCrumbs :items="breadcrumbs" />

        <ButtonsCard
          :hasAnyChanges="scheduleTemplate.hasAnyChanges"
          :showUploadBtn="!scheduleTemplate.lines.length"
          :uploadBtnLoading="uploadBtnLoading"
          :saveBtnLoading="saveBtnLoading"
          @uploadScheduleTemplate="uploadScheduleTemplate"
          @saveScheduleTemplate="saveScheduleTemplate"
        />
      </div>

      <ScheduleTemplateTable
        v-if="dataLoaded"
        :infoBlockHeight="infoBlockHeight"
        :scheduleTemplateId="scheduleTemplateId"
        :scheduleTemplate="scheduleTemplate"
        @saveScheduleTemplateLine="saveScheduleTemplateLine"
        @removeScheduleTemplateLine="removeScheduleTemplateLine"
      />
    </div>
  </div>
</template>

<script>
import { scheduleApi, reportApi } from '@/api'
import { mapGetters } from 'vuex'
import { EventPlace, ScheduleTemplate, ScheduleTemplateLine } from '@/models'
import PageTitle from '@/components/ui/Title'
import BreadCrumbs from '@/components/ui/BreadCrumbs'
import ButtonsCard from '@/components/department/scheduleTemplate/Buttons'
import ScheduleTemplateTable from '@/components/department/scheduleTemplate/Table'

export default {
  name: 'ScheduleTemplate',

  metaInfo: {
    title: 'Шаблон расписания'
  },

  components: {
    PageTitle,
    BreadCrumbs,
    ButtonsCard,
    ScheduleTemplateTable
  },

  async created() {
    this.setScheduleTemplateId()
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    await this.getScheduleTemplate()
    this.dataLoaded = true
    this.setInfoBlockHeight()
  },

  mounted() {
    this.hideHtmlOverflow()
    window.addEventListener("resize", this.setInfoBlockHeight)
  },

  destroyed() {
    this.showHtmlOverflow()
    this.disableBeforeUnloadMode()
    window.removeEventListener("resize", this.setInfoBlockHeight)
  },

  beforeRouteLeave(to, from, next) {
    this.scheduleTemplate.hasAnyChanges
      ? window.confirm('Возможно, внесенные изменения не сохранятся.') ? next() : next(false)
      : next()
  },

  data: () => ({
    breadcrumbs: [
      { text: 'Список шаблонов', to: '/department/schedule-templates', disabled: false },
      { text: 'Шаблон расписания', disabled: true }
    ],

    dataLoaded: false,
    scheduleTemplateId: null,
    infoBlockHeight: 0,
    saveBtnLoading: false,
    uploadBtnLoading: false,

    scheduleTemplate: new ScheduleTemplate()
  }),

  computed: {
    ...mapGetters('department', ['departmentId', 'departmentName'])
  },

  methods: {
    setScheduleTemplateId() {
      this.scheduleTemplateId = +this.$route.params.scheduleTemplateId
    },

    async getScheduleTemplate() {
      try {
        const data = await scheduleApi.getScheduleTemplateById(this.scheduleTemplateId)
        this.scheduleTemplate = ScheduleTemplate.buildFromAPI(data)
        this.scheduleTemplate.sortLinesByOrder()
      } catch (e) {
        console.log(e.status)
      }
    },

    async saveScheduleTemplate() {
      try {
        this.saveBtnLoading = true

        const data = await scheduleApi.saveScheduleTemplate(this.departmentId, this.scheduleTemplate.getForAPI())
        this.scheduleTemplate = ScheduleTemplate.buildFromAPI(data)
        this.scheduleTemplate.sortLinesByOrder()
        this.disableBeforeUnloadMode()
        this.$toastr('success', '', 'Шаблон расписания сохранен')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения шаблона расписания')
      } finally {
        this.saveBtnLoading = false
      }
    },

    async uploadScheduleTemplate(file) {
      try {
        this.uploadBtnLoading = true

        const body = new FormData()
        body.append('file', file, file.name)

        const data = await reportApi.uploadScheduleTemplate(this.departmentId, body)
        this.scheduleTemplate.lines = data?.map(line => ScheduleTemplateLine.buildFromAPI(line)) || []

        if (localStorage.getItem(`schTemp-${this.scheduleTemplateId}`)) {
          const place = JSON.parse(localStorage.getItem(`schTemp-${this.scheduleTemplateId}`))
          const eventPlace = EventPlace.buildFromAPI(place)
          this.scheduleTemplate.lines.forEach(line => line.eventPlace = eventPlace)
        }

        this.scheduleTemplate.sortLinesByOrder()
        this.scheduleTemplate.hasAnyChanges = true
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка загрузки файла шаблона расписания')
      } finally {
        this.uploadBtnLoading = false
      }
    },

    saveScheduleTemplateLine(line) {
      try {
        line.rowOrder
          ? this.scheduleTemplate.editLine(line)
          : this.scheduleTemplate.addLine(line)

        this.enableBeforeUnloadMode()
      } catch (e) {

      }
    },

    removeScheduleTemplateLine(line) {
      this.scheduleTemplate.removeLine(line)
      this.enableBeforeUnloadMode()
    },

    setInfoBlockHeight() {
      this.$nextTick(() => {
        const infoBlock = this.$refs.infoBlock

        if (infoBlock) {
          this.infoBlockHeight = infoBlock.offsetHeight
        }
      })
    },

    enableBeforeUnloadMode() {
      this.scheduleTemplate.hasAnyChanges = true
      window.addEventListener("beforeunload", this.beforeUnload, { capture: true })
    },

    disableBeforeUnloadMode() {
      this.scheduleTemplate.hasAnyChanges = false
      window.removeEventListener("beforeunload", this.beforeUnload, { capture: true })
    },

    beforeUnload(event) {
      event.preventDefault()
      return event.returnValue = ""
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>