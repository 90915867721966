<template>
  <v-card class="base-card pa-5 mt-4">
    <div class="d-flex">
      <v-btn
        small
        color="primary"
        class="white--text px-4"
        @click="createScheduleTemplateLine"
      >
        <v-icon left small>mdi-plus</v-icon>Добавить строку
      </v-btn>

      <div v-if="showUploadBtn">
        <v-btn
          :loading="uploadBtnLoading"
          small
          color="success"
          class="white--text px-4 ml-3"
          @click="handleFileImport"
        >
          <v-icon left size="20">mdi-file-upload-outline</v-icon>Загрузить из Excel
        </v-btn>

        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="onFileChanged"
        >
      </div>

      <v-btn
        v-else
        :disabled="!hasAnyChanges"
        :loading="saveBtnLoading"
        small
        color="success"
        class="white--text px-4 ml-3"
        @click="$emit('saveScheduleTemplate')"
      >
        <v-icon left size="20">mdi-content-save-outline</v-icon>Сохранить
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { eventBus } from '@/main'

export default {
  name: 'ScheduleTemplateButtons',

  props: {
    hasAnyChanges: Boolean,
    showUploadBtn: Boolean,
    uploadBtnLoading: Boolean,
    saveBtnLoading: Boolean
  },

  data: () => ({
    selectedFile: null
  }),

  methods: {
    createScheduleTemplateLine() {
      eventBus.$emit('createScheduleTemplateLine')
    },

    handleFileImport() {
      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      this.selectedFile = e.target.files[0]

      if (this.selectedFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$toastr('warning', '', 'Файл должен быть в расширении .xlsx')
        return
      }

      if (this.selectedFile.size > 51200) {
        this.$toastr('warning', '', 'Файл не должен быть больше 50 КБайт')
        return
      }

      this.$emit('uploadScheduleTemplate', this.selectedFile)
    }
  }
}
</script>